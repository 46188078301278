import React from 'react';
import { supabase } from '../../src/providers/supabase/supabase'; // Make sure this path is correct

const GoogleSignInButton: React.FC = () => {
  // Ensure the handler matches the expected type
  const handleGoogleSignIn: React.MouseEventHandler<HTMLButtonElement> = async (event): Promise<void> => {
    event.preventDefault(); // Prevent default button click behavior if needed

    try {
      // Trigger the Google Sign-In
      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
      });

      if (error) {
        console.error('Error during Google Sign-In:', error.message);
      } else {
        console.log('Google Sign-In successful');
      }
    } catch (err: unknown) {
      // Ensure type safety
      if (err instanceof Error) {
        console.error('Unexpected error during Google Sign-In:', err.message);
      } else {
        console.error('An unknown error occurred during Google Sign-In');
      }
    }
  };

  return (
    <button onClick={handleGoogleSignIn}>Sign In with Google</button>
  );
};

export default GoogleSignInButton;

import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  CardActions,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/material";
import { Form, useLogin, useNotify, useSafeSetState } from "ra-core";
import { Login, TextInput } from "react-admin";
import { SubmitHandler } from "react-hook-form";
import { Link } from "react-router-dom";
import { ForgotPasswordPage } from "ra-supabase";
import { supabase } from "../../src/providers/supabase/supabase";
import GoogleSignInButton from "./GoogleSignInButton";
import { User } from "@supabase/supabase-js"; // Import the User type from Supabase

const PREFIX = "RaLoginForm";

export const LoginFormClasses = {
  content: `${PREFIX}-content`,
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
};

const StyledForm = styled(Form, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${LoginFormClasses.content}`]: {
    width: 300,
  },
  [`& .${LoginFormClasses.button}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${LoginFormClasses.icon}`]: {
    margin: theme.spacing(0.3),
  },
}));

export const LoginForm = () => {
  const [loading, setLoading] = useSafeSetState(false);
  const login = useLogin();
  const notify = useNotify();
  const [user, setUser] = useState<User | null>(null); // Use Supabase's User type

  useEffect(() => {
    // Fetch the authenticated user session initially
    const fetchSession = async () => {
      const { data } = await supabase.auth.getSession();
      const userFromSession = data?.session?.user ?? null;
      setUser(userFromSession);
    };

    fetchSession();

    // Listen for authentication state changes
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        const userFromSession = session?.user ?? null;
        setUser(userFromSession);
      }
    );

    // Unsubscribe from the auth listener on component unmount
    const unsubscribe = authListener?.subscription;
    return () => {
      unsubscribe?.unsubscribe();
    };
  }, []);

  const submit: SubmitHandler<FieldValues> = (values) => {
    setLoading(true);
    login(values)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notify(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message,
          {
            type: "error",
            messageArgs: {
              _:
                typeof error === "string"
                  ? error
                  : error && error.message
                  ? error.message
                  : undefined,
            },
          }
        );
      });
  };

  return (
    <Login>
      <StyledForm onSubmit={submit} mode="onChange" noValidate>
        <CardContent className={LoginFormClasses.content}>
          <TextInput
            autoFocus
            source="email"
            label="Email"
            autoComplete="email"
          />
          <TextInput
            source="password"
            label="Password"
            type="password"
            autoComplete="current-password"
          />
        </CardContent>
        <CardActions sx={{ flexDirection: "column", gap: 1 }}>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={loading}
            fullWidth
            className={LoginFormClasses.button}
          >
            {loading ? (
              <CircularProgress
                className={LoginFormClasses.icon}
                size={19}
                thickness={3}
              />
            ) : (
              "Sign In"
            )}
          </Button>
          <GoogleSignInButton />
          <Typography
            component={Link}
            to={ForgotPasswordPage.path}
            variant="caption"
          >
            Forgot your password?
          </Typography>
        </CardActions>
      </StyledForm>
    </Login>
  );
};

type FieldValues = Record<string, any>;
